@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "@ng-select/ng-select/themes/material.theme.css";
[hidden] {
  display: none !important;
}

body {
  background: #f5f5f5;
}

.bg-default {
  background: linear-gradient(to top right, #999999, #b3b3b3);
  background: -o-linear-gradient(to top right, #999999, #b3b3b3);
  background: -moz-linear-gradient(to top right, #999999, #b3b3b3);
  background: -webkit-linear-gradient(to top right, #999999, #b3b3b3);
}

.bg-primary {
  background: linear-gradient(to top right, #242852, #ffd17c);
  background: -o-linear-gradient(to top right, #242852, #ffd17c);
  background: -moz-linear-gradient(to top right, #242852, #ffd17c);
  background: -webkit-linear-gradient(to top right, #242852, #ffd17c);
}

.bg-success {
  background: linear-gradient(to top right, #16be9a, #42e697);
  background: -o-linear-gradient(to top right, #16be9a, #42e697);
  background: -moz-linear-gradient(to top right, #16be9a, #42e697);
  background: -webkit-linear-gradient(to top right, #16be9a, #42e697);
}

.bg-danger {
  background: linear-gradient(to top right, #242852, #ffd17c);
  background: -o-linear-gradient(to top right, #242852, #ffd17c);
  background: -moz-linear-gradient(to top right, #242852, #ffd17c);
  background: -webkit-linear-gradient(to top right, #242852, #ffd17c);
}

.bg-warning {
  background: linear-gradient(to top right, #ff7403, #fac980);
  background: -o-linear-gradient(to top right, #ff7403, #fac980);
  background: -moz-linear-gradient(to top right, #ff7403, #fac980);
  background: -webkit-linear-gradient(to top right, #ff7403, #fac980);
}

.bg-info {
  background: linear-gradient(to top right, #a3bde1, #15f8bf);
  background: -o-linear-gradient(to top right, #a3bde1, #15f8bf);
  background: -moz-linear-gradient(to top right, #a3bde1, #15f8bf);
  background: -webkit-linear-gradient(to top right, #a3bde1, #15f8bf);
}

.mat-raised-button[disabled] {
  cursor: not-allowed !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

button.mat-raised-button {
  color: #242852;
  font-size: 16px;
}

button.mat-raised-button:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

button.mat-default {
  background: linear-gradient(to top right, #999999, #b3b3b3);
  background: -o-linear-gradient(to top right, #999999, #b3b3b3);
  background: -moz-linear-gradient(to top right, #999999, #b3b3b3);
  background: -webkit-linear-gradient(to top right, #999999, #b3b3b3);
  color: #fff;
}

button.mat-primary {
  background: linear-gradient(to top right, #242852, #393a3e);
  background: -o-linear-gradient(to top right, #242852, #393a3e);
  background: -moz-linear-gradient(to top right, #242852, #393a3e);
  background: -webkit-linear-gradient(to top right, #242852, #393a3e);
  color: #fff;
}

button.mat-success {
  background: linear-gradient(to top right, #16be9a, #42e697);
  background: -o-linear-gradient(to top right, #16be9a, #42e697);
  background: -moz-linear-gradient(to top right, #16be9a, #42e697);
  background: -webkit-linear-gradient(to top right, #16be9a, #42e697);
  color: #fff;
}

button.mat-danger {
  background: linear-gradient(to top right, #242852, #393a3e);
  background: -o-linear-gradient(to top right, #242852, #393a3e);
  background: -moz-linear-gradient(to top right, #242852, #393a3e);
  background: -webkit-linear-gradient(to top right, #242852, #393a3e);
  color: white;
  font-size: 16px;
}

button.mat-warning {
  background: linear-gradient(to top right, #ff7403, #fca063);
  background: -o-linear-gradient(to top right, #ff7403, #fca063);
  background: -moz-linear-gradient(to top right, #ff7403, #fca063);
  background: -webkit-linear-gradient(to top right, #ff7403, #fca063);
  color: #fff;
}

button.mat-info {
  background: linear-gradient(to top right, #242852, #a3bde1);
  background: -o-linear-gradient(to top right, #242852, #a3bde1);
  background: -moz-linear-gradient(to top right, #242852, #a3bde1);
  background: -webkit-linear-gradient(to top right, #242852, #a3bde1);
  color: #fff;
  font-size: 16px;
}

[hidden] {
  display: none !important;
}

@font-face {
  font-family: lato-light;
  src: url("../fonts/Lato/Lato-Light.ttf");
}
@font-face {
  font-family: lato;
  src: url("../fonts/Lato/Lato-Regular.ttf");
}
@font-face {
  font-family: lato-bold;
  src: url("../fonts/Lato/Lato-Bold.ttf");
}
@font-face {
  font-family: lato-black;
  src: url("../fonts/Lato/Lato-Black.ttf");
}
@font-face {
  font-family: lato-bold-italic;
  src: url("../fonts/Lato/Lato-BoldItalic.ttf");
}
@font-face {
  font-family: lato-italic;
  src: url("../fonts/Lato/Lato-Italic.ttf");
}
@font-face {
  font-family: "lato light";
}
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 32px / 48px "Roboto", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px "Roboto", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px "Roboto", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px "Roboto", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 500 calc(16px * 0.83) / 24px "Roboto", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 500 calc(16px * 0.67) / 24px "Roboto", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px "Roboto", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 500 16px / 24px "Roboto", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px "Roboto", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px "Roboto", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px "Roboto", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px "Roboto", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px "Roboto", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 500 16px / 24px "Roboto", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: "Roboto", sans-serif;
}

.mat-card {
  font-family: "Roboto", sans-serif;
}

.mat-card-title {
  font-size: 32px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 16px;
}

.mat-checkbox {
  font-family: "Roboto", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: "Roboto", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 16px;
}

.mat-calendar {
  font-family: "Roboto", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 500;
}

.mat-dialog-title {
  font: 500 20px / 32px "Roboto", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 500 16px / 24px "Roboto", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: "Roboto", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 16px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: "Roboto", sans-serif;
}

.mat-select {
  font-family: "Roboto", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: "Roboto", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: "Roboto", sans-serif;
}

.mat-step-label {
  font-size: 16px;
  font-weight: 500;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: "Roboto", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px "Roboto", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: "Roboto", sans-serif;
}

.mat-list-option {
  font-family: "Roboto", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 16px;
}
.mat-list-base .mat-subheader {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px "Roboto", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: "Roboto", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 500;
  font-size: 16px;
}

.mat-toolbar.mat-primary {
  background: #ffffff;
  color: #242852;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

input, select {
  font-family: "Roboto", sans-serif;
}

.main-container {
  padding: 55px 10px 0px 10px;
}

.tooltip-red {
  background: #242852;
}

.defaultColor {
  color: #242852;
}

.greenColor {
  color: green;
}

.mb-20 {
  margin-bottom: 20px;
}

.open-container > * {
  width: 100%;
}

.footer {
  position: absolute;
  font-size: 1em;
  right: 10px;
  bottom: 10px;
  color: #393a3e;
}
.footer a {
  color: #02549a;
  text-decoration: none;
}
.footer a:hover {
  color: #02549a;
  text-decoration: underline;
}

.myPreviewClass .mat-dialog-container {
  background-color: transparent;
  box-shadow: none;
  overflow: hidden;
}

.hint {
  cursor: help !important;
  border: 3px solid #3972f6 !important;
}

.hint-tooltip {
  font-size: 12pt;
  background-color: darkgrey;
}

.tooltip-example {
  text-align: center;
  padding: 0 50px;
}

.tooltip-example [tooltip] {
  display: inline-block;
  margin: 50px 20px;
  width: 180px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
}

.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: #f8f8f2;
  padding: 3px 8px;
  background: #282a36;
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: black transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent black transparent;
}

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent black;
}

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent black transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}

.dragBoundary {
  height: 90vh;
}

.bdc-walk-popup .title .header {
  color: #3972f6 !important;
}
.bdc-walk-popup .buttons button {
  color: #3972f6 !important;
}
.bdc-walk-popup .container {
  color: #242852 !important;
}

.bdc-walk-dialog mat-dialog-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 35px 40px;
}
.bdc-walk-dialog h1 {
  text-align: center;
  margin: 0 0 25px 0;
}
.bdc-walk-dialog .buttons {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bdc-walk-dialog .buttons button.mat-raised-button {
  min-width: 180px;
  height: 40px;
  font-weight: bold;
}
.bdc-walk-dialog .buttons button.mat-raised-button:not(:disabled) {
  background-color: #242852;
}
.bdc-walk-dialog .buttons a {
  color: #242852 !important;
  text-decoration: none !important;
  margin-top: 15px;
}

h1 {
  font-weight: 400;
  font-size: 2.5em;
}

.m-0 {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.mt5 {
  padding-top: 5px;
}

.mt10 {
  padding-top: 10px;
}

.mt15 {
  padding-top: 15px;
}

.mt20 {
  padding-top: 20px;
}

.mb5 {
  padding-bottom: 5px;
}

.mb10 {
  padding-bottom: 10px;
}

.mb15 {
  padding-bottom: 15px;
}

.mb20 {
  padding-bottom: 20px;
}

.ml5 {
  padding-left: 5px;
}

.ml10 {
  padding-left: 10px;
}

.ml15 {
  padding-left: 15px;
}

.ml20 {
  padding-left: 20px;
}

.mr5 {
  padding-right: 5px;
}

.mr10 {
  padding-right: 10px;
}

.mr15 {
  padding-right: 15px;
}

.mr20 {
  padding-right: 20px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.pl20 {
  padding-left: 20px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

@media screen and (max-width: 992px) {
  .push-right #sidebar {
    left: 0px !important;
  }
}
@media screen and (min-width: 992px) {
  .push-right #sidebar {
    left: 0px !important;
  }
}